import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "gatsby";
import PageWrapper from "../components/PageWrapper";
import { Title, Section, Box, Button } from "../components/Core";

const NotFoundPage = () => {
  return (
    <PageWrapper>
      <Box bg="primary">
        {/* <!-- Hero Area --> */}
        <Section hero className="position-relative">
          <Container>
            <Box
              pt={["30px", null, "40px", "50px"]}
              pb={["40px", null, "60px", "80px"]}
            >
              <Box className="d-flex flex-column align-items-center text-center">
                <Title
                  color="light"
                  variant="card"
                  mb="2.5rem"
                  css={`
                    font-size: 1.3125rem;
                    line-height: 1.5;
                    font-weight: 400;
                  `}
                >
                  You're going the wrong way
                </Title>

                <Title color="light" variant="hero" mb="3.75rem">
                  404 Not Found
                </Title>
                <Link to="/">
                  <Button variant="secondary" arrowRight>
                    Go back
                  </Button>
                </Link>
              </Box>
            </Box>
          </Container>
        </Section>
      </Box>
    </PageWrapper>
  );
};

export default NotFoundPage;
